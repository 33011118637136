(function () {
    const isiOnPage = document.getElementById('safety-isi');
    const isi = document.getElementById('lower-third');

    function checkIsiPos() {
        let isiPos = isi.offsetTop;
        let isiOnPagePos = isiOnPage.offsetTop - window.pageYOffset;

        if (isiPos >= isiOnPagePos) {
            isi.style.visibility = 'hidden';
        } else {
            isi.style.visibility = 'visible';
        }
    }

    window.addEventListener('resize', checkIsiPos);
    window.addEventListener('scroll', checkIsiPos);
    window.addEventListener('load', checkIsiPos);
})();
