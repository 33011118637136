import smoothscroll from 'smoothscroll-polyfill';

//import gsap from 'gsap';
//import '../../node_modules/gsap/dist/ScrollToPlugin';


(function () {
  smoothscroll.polyfill();

  const links = document.querySelectorAll("a.scroll-link");
  let href;

  for (const link of links) {
    link.addEventListener("click", clickHandler);
  }

  function clickHandler(e) {
    e.preventDefault();
    
    href = this.getAttribute("href").toLowerCase();

    //gsap.to(window, {duration: 2, scrollTo:"#combination"});
    
    
    
    const headerHeight = document.getElementById('globalHeader').offsetHeight;
    const offsetTop = document.querySelector(href).offsetTop;
    const headerOffset = document.getElementById('sHeader').offsetTop;
    let linkOffset = 107;
    let fullOffset = offsetTop - linkOffset;

//    if (window.innerWidth <= 992 && window.pageYOffset > headerOffset) {
//      fullOffset = offsetTop - 107;
//    } else if (window.innerWidth <= 992 && window.pageYOffset < headerOffset) {
//      fullOffset = offsetTop - (headerHeight - 40);
//    } else if (window.innerWidth > 992 && window.pageYOffset > headerOffset) {
//      fullOffset = offsetTop - 160;
//    } else {
//      fullOffset = offsetTop - (headerHeight - 50);
//    }

    scroll({
      top: fullOffset - linkOffset,
      behavior: "smooth"
    });
  }
})();