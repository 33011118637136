(function () {
    const swipeImg = document.getElementById('table-swipe');
    const swipeTable = document.getElementById('immunization-wrapper');
    let tableIsClicked = false;

    let swipeWindowWidth = window.innerWidth;

    window.onresize = () => {
        swipeWindowWidth = window.innerWidth
        checkWindowWidth();
    }

    swipeTable.addEventListener('click', () => {
        if (swipeWindowWidth <= 992) {
            tableIsClicked = true;
        }
        checkWindowWidth();
    });

    swipeTable.addEventListener('scroll', () => {
        if (swipeWindowWidth <= 992) {
            tableIsClicked = true;
        }
        checkWindowWidth();
    });

    const checkWindowWidth = () => {
        if (swipeWindowWidth <= 992 && !tableIsClicked) {
            swipeImg.style.display = "block"
        } else {
            swipeImg.style.display = "none"
        }
    }

    checkWindowWidth();
})();