(function () {
    const body = document.getElementsByTagName('body')[0];
    const header = document.getElementById('sHeader');
    const sectionContainer = document.getElementById('section-container');
    const infoContainer = document.getElementById('info-container');
    const quadLogo = document.getElementById('quad-logo-container');
    const infoLinks = document.getElementById('info-links-col');
    const infoBtn = document.getElementById('info-btn-col');
    const navBarMain= document.getElementById('navbar-main');

    let sticky = header.offsetTop;
    let windowWidth = window.innerWidth;

    window.onscroll = () => { stickyHeader() };

    window.addEventListener('resize', () => {
        getWindowSize();
        stickyHeader();
    });

    function getWindowSize() {
        windowWidth = window.innerWidth;
    }

    const stickyHeader = () => {
        if (windowWidth <= 992) {

            if (window.pageYOffset > sticky) {                
                header.classList.add("sticky");
                quadLogo.style.display = 'none';
                infoLinks.style.display = 'none';
                infoBtn.style.maxWidth = '100%';
                infoBtn.style.flexBasis = '100%';
                infoBtn.style.paddingLeft = '0px';
                infoBtn.style.paddingRight = '0px';
                sectionContainer.style.paddingTop = navBarMain.offsetHeight+infoContainer.offsetHeight+"px";
            } else {
                quadLogo.style.display = 'flex';
                infoLinks.style.display = 'flex';
                infoBtn.style.maxWidth = '50%';
                infoBtn.style.flexBasis = '50%';
                infoBtn.style.paddingLeft = '15px';
                infoBtn.style.paddingRight = '15px';
                header.classList.add("sticky");
                header.classList.remove("sticky");
                sectionContainer.style.paddingTop = "0px";
            }
        } else {
            quadLogo.style.display = 'flex';
            infoLinks.style.display = 'flex';
            infoBtn.style.maxWidth = '50%';
            infoBtn.style.flexBasis = '50%';
            infoBtn.style.paddingLeft = '15px';
            infoBtn.style.paddingRight = '15px';
            
            if (window.pageYOffset > sticky) {
                header.classList.add("sticky");
                sectionContainer.style.paddingTop = navBarMain.offsetHeight+infoContainer.offsetHeight+"px";
            } else {
                header.classList.remove("sticky");
                sectionContainer.style.paddingTop = "0px";
            }

        }

        setScrollSpyData();

    }

    const setScrollSpyData = () => {
        if (windowWidth <= 992) {
            body.dataset.offset = 278;
            if (window.pageYOffset > sticky) {
                body.dataset.offset = 107;
            }

        } else {
            body.dataset.offset = 261;
            if (window.pageYOffset > sticky) {
                body.dataset.offset = 216;
            }
        }
    }

    setScrollSpyData();
    stickyHeader();
})();