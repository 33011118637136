//import '../../node_modules/gsap/dist/gsap';
//import '../../node_modules/gsap/dist/ScrollToPlugin';
import '../../node_modules/bootstrap/js/dist/util';
import '../../node_modules/bootstrap/js/dist/scrollspy';
import '../../node_modules/bootstrap/js/dist/collapse';
import '../../node_modules/bootstrap/js/dist/tab';
import './header';
import './menu';
import './smoothScroll';
import './isi';
import './swipe-image';