import $ from "jquery";
(function () {
  const navTitle = document.getElementById('navTitle');
  let currSection = 'Overview'
  let navActive = false;
  navTitle.innerText = 'Overview';
  
  $(window).on('activate.bs.scrollspy', function (e, obj) {
    checkSection(obj.relatedTarget);
  });

  function updateNavText(text) {
    navTitle.innerText = text;
    if (!navTitle.classList.contains('blue')) {
      navTitle.classList.add('blue');
    }
  }

  function checkSection(section) {
    switch (section) {
      case '#overview':
        currSection = 'Overview';
        break;
      case '#combination':
        currSection = 'Using a Combination Vaccine';
        break;
      case '#immune':
        currSection = 'Immune Response';
        break;
      case '#safety':
        currSection = 'Safety Data';
        break;
      default:
        currSection = 'Choose a Section';
    }
    if(!navActive)
    updateNavText(currSection)
  }
  $('#navbarNavAltMarkup').on('show.bs.collapse', function () {
    navTitle.innerText = 'Choose a Section';
    if (navTitle.classList.contains('blue')) {
      navTitle.classList.remove('blue');
    }
    navActive = true;
  })
  $('#navbarNavAltMarkup').on('hidden.bs.collapse', function () {
    navActive = false;
    updateNavText(currSection);
  })
  $('.navbar-nav>a').on('click', function () {
    $('.navbar-collapse').collapse('hide');
  });
   $('#ot-sdk-btn').on('click', function (e) {
        e.preventDefault();
    })
})();
